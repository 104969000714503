header {
    z-index: z('navigation');
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 2rem 2.5rem;
    background: $color-off-white;
    border-bottom: 0.2rem dashed $color-off-black;
    @include flexbox(row, wrap, space-between, center, center);
    text-transform: uppercase;
    @include media('<tablet-portrait') {
        border-bottom-width: 0.3rem; }
    @include media('<phone') {
        padding: 3rem; } }

.header__navigation__content {
    width: auto;
    @include flexbox(row, wrap, flex-start, center, center);
    gap: 8rem;
    @include media ('>=tablet-portrait', 'portrait') {
        width: 100%;
        justify-content: space-between; }
    @include media('<tablet-portrait') {
        gap: 6rem; }
    @include media('<phone') {
        gap: 3rem; } }

.header__logo {
    width: 19.4rem;
    position: relative;
    z-index: 1;
    @include media('<tablet-portrait') {
        width: 23.5rem; }
    @include media('<phone') {
        width: 28.5rem; }
    a {
        display: block; }
    // @include media('<phone')
    // &:hover
 }    //     @media (hover: hover)

.header__navigation {
    position: relative;
    z-index: 1;
    @include media('<tablet-portrait') {
        display: none;
        width: 100%;
        transform: translateY(-5rem);
        opacity: 0;
        margin-left: 45%;
        margin-bottom: 3rem;
        transition: transform 1s 0.5s ease; }
    @include media('<phone') {
        margin-left: 32%;
        margin-bottom: 1rem; } }

.header__navigation__list {
    width: auto;
    @include flexbox(row, wrap, flex-start, center, center);
    gap: 9rem;
    @include media('<tablet-portrait') {
        @include flexbox(column, wrap, flex-start, flex-start, flex-start);
        gap: 2rem; }
    #trigger-projects {
        @include media('<tablet-portrait') {
            pointer-events: none; } }
    .header__navigation__item {
        cursor: pointer;
        .navigation__link {
            color: $color-dark-gray;
            &:hover {
                @media (hover: hover) {
                    color: $color-off-black; } }
            &.active {
                color: $color-off-black; } }
        .button {
            &:not(.navigation__link) {
                color: $color-dark-gray;
                &:hover {
                    @media (hover: hover) {
                        color: $color-off-black; } } } }
        &.button--square {
            padding-left: 2rem;
            color: $color-dark-gray;
            @include media('<tablet-portrait') {
                padding-left: 0; }
            &::before {
                width: 1.2rem;
                height: 1.2rem;
                top: calc(50% - 0.65rem);
                left: 0;
                border-color: $color-dark-gray;
                @include media('<tablet-portrait') {
                    display: none; } }
            &:hover {
                @media (hover: hover) {
                    color: $color-off-black;
                    &::before {
                        border-color: $color-off-black; } } }
            &.active {
                color: $color-off-black;
                &::before {
                    border-color: $color-off-black; } } } } }

.header__hamburger {
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: none;
    z-index: 1;
    @include media('<tablet-portrait') {
        display: block; }
    @include media('<phone') {
        top: 3rem;
        right: 2rem; }
    .hamburger__bar {
        width: 3.5rem;
        height: 0.2rem;
        position: absolute;
        top: 2rem;
        left: 0;
        background: $color-off-black;
        display: block;
        &:last-of-type {
            transform: rotate(90deg); } } }

.header__navigation__projects__list {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;

    @include flexbox(row, wrap, center, center, center);

    background: rgba($color-off-white, 0.9);

    opacity: 0;
    transform: translateY(-105%);
    transition: transform 0.6s ease-out;
    @include media('<phone') {
        align-items: flex-start;
        align-content: flex-start;
        padding: 34rem 3rem 3rem 3rem; }
    ul {
        width: 53%;
        height: 30rem;
        position: relative;
        transition: transform 0.5s 0s ease-out;
        // @include media('>=phone')
        //     transform: scale(0)
        @include media('<tablet-portrait') {
            width: 85%; }
        @include media('<phone') {
            width: 100%;
            @include flexbox(column, nowrap, flex-start, flex-start, flex-start);
            transition: transform 0.5s 0s ease-out, opacity 0.5s 0s ease-out;
            opacity: 0;
            transform: translateX(-50%); }
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            @include background(url('../../../public/images/menu/menu_ellipses.svg'), 100% auto, center, no-repeat);
            animation: animaEllipses 7s ease infinite alternate;
            @include media('<phone') {
                display: none; } }
        &::after {
            content: '';
            width: 4.5rem;
            height: 6rem;
            @include background(url('../../../public/images/sthorm/sthorm_iso.svg'), 100% auto, center, no-repeat);
            position: absolute;
            top: calc(50% - 3rem);
            left: calc(50% - 2.25rem);
            animation: animaHead 8s ease infinite alternate;
            @include media('<phone') {
                display: none; } }
        li {
            text-transform: lowercase;
            position: relative;
            a {
                padding: 0.5rem;
                display: inline-block; }
            &::before {
                content: '';
                width: 2rem;
                height: 2rem;
                background: $color-off-black;
                position: absolute; }
            @include media('<phone') {
                top: 0.9rem;
                left: 0;
                font-size: 3rem;
                padding-left: 3.5rem;
                margin-bottom: 2rem;
                &::before {
                    top: 1rem;
                    left: 0; } }
            @include media('>=phone') {
                position: absolute;
                animation: animaProjectsY 5s ease-out infinite alternate;
                &::before {
                    width: 1.3rem;
                    height: 1.6rem; }
                &:not(.project__list__item--comingsoon) {
                    &::after {
                        content: '';
                        width: 0;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: $color-off-black;
                        z-index: -1; } }
                &:hover {
                    &:not(.project__list__item--comingsoon) {
                        @media (hover: hover) {
                            color: $color-off-white;
                            &::before {
                                background: $color-dark-gray; }
                            &::after {
                                width: 100%; } } } }
                &:nth-of-type(1) {
                    top: 7rem;
                    left: 21rem;
                    &::before {
                        top: 100%;
                        left: 0.5rem; } }
                &:nth-of-type(2) {
                    top: 14rem;
                    right: 5rem;
                    animation: animaProjectsH 6s ease-out infinite alternate;
                    &::before {
                        top: 0.3rem;
                        left: -1.6rem; } }
                &:nth-of-type(3) {
                    top: 14rem;
                    left: 7.5rem;
                    animation: animaProjectsH 6s ease-out infinite alternate;
                    &::before {
                        top: 0.5rem;
                        right: -2rem; } }
                &:nth-of-type(4) {
                    bottom: 6.5rem;
                    left: 45rem;
                    &::before {
                        top: 0.3rem;
                        left: -1.6rem; } }
                &:nth-of-type(5) {
                    bottom: 5.5rem;
                    right: 20rem;
                    animation: animaProjectsH 6s ease-out infinite alternate;
                    &::before {
                        top: 0;
                        left: -2rem; } }
                &:nth-of-type(6) {
                    top: 7.5rem;
                    left: 49rem;
                    &::before {
                        top: 100%;
                        left: 0.5rem; } }
                &:nth-of-type(7) {
                    bottom: 10.5rem;
                    right: 15rem;
                    &::before {
                        top: 0.3rem;
                        left: -2rem; } }
                &:nth-of-type(8) {
                    bottom: 9rem;
                    left: 22rem;
                    animation: animaProjectsH 6s ease-out infinite alternate;
                    &::before {
                        top: 0.3rem;
                        left: -1.6rem; } }
                &:nth-of-type(9) {
                    top: 11.5rem;
                    right: 18rem;
                    &::before {
                        top: 0.3rem;
                        left: -2rem; } } }
            &.project__list__item--comingsoon {
                pointer-events: none;
                cursor: not-allowed;
                &::after {
                    content: '(coming soon)';
                    width: auto;
                    height: auto;
                    white-space: nowrap;
                    position: absolute;
                    left: 105%;
                    top: 0;
                    opacity: 0.5; } } } } }

.header__text__slider__wrapper {
    width: 40%;
    @include media('<tablet-portrait') {
        display: none; }
    @include media ('>=tablet-portrait', 'portrait') {
        width: 100%;
        margin-top: 2rem; } }


//Header Active
header {
    &.active {
        .header__navigation {
            @include media('<tablet-portrait') {
                display: block;
                transform: translateY(0);
                opacity: 1; } }
        .header__navigation__projects__list {
            &.active {
                transform: translateY(0);
                opacity: 1;
                transition: transform 0.6s ease-out; }
            ul {
                transition: transform 0.5s 0.6s ease-out, opacity 0.5s 0.6s ease-out;
                @include media('<phone') {
                    transform: translateY(0);
                    opacity: 1; }
                // @include media('>=phone')
 } }                //     transform: scale(1)
        .header__hamburger {
            .hamburger__bar {
                transform: rotate(90deg);
                opacity: 0;
                &:last-of-type {
                    transform: rotate(180deg);
                    opacity: 1; } } } } }


//Dark Theme
.dark-theme {
    header {
        background: $color-off-black;
        border-bottom-color: $color-off-white; }
    .header__logo {
        img {
            filter: brightness(0) invert(1); }
        // &:hover
 }        //     @media (hover: hover)
    .header__hamburger {
        filter: brightness(0) invert(1); }
    .header__navigation__item {
        .navigation__link {
            &:hover {
                @media (hover: hover) {
                    color: $color-off-white; } }
            &.active {
                color: $color-off-white; } }
        .button {
            &:not(.navigation__link) {
                &:hover {
                    @media (hover: hover) {
                        color: $color-off-white; } } } }
        &.button--square {
            &:hover {
                @media (hover: hover) {
                    color: $color-off-white;
                    &::before {
                        border-color: $color-off-white; } } }
            &.active {
                color: $color-off-white;
                &::before {
                    border-color: $color-off-white; } } } }
    .header__navigation__projects__list {
        background: rgba($color-off-black, 0.9);
        ul {
            &::before {
                filter: brightness(0) invert(1); }
            &::after {
                filter: brightness(0) invert(1); }
            li {
                &::before {
                    background: $color-off-white; }
                &:not(.project__list__item--comingsoon) {
                    &::after {
                        background: $color-off-white; }
                    &:hover {
                        &:not(.project__list__item--comingsoon) {
                            @media (hover: hover) {
                                color: $color-off-black;
                                &::before {
                                    background: $color-dark-gray; } } } } } } } } }

//Animations Menu
@keyframes animaEllipses {
    from {
        transform: scaleY(1); }
    to {
        transform: scaleY(0.8); } }
@keyframes animaHead {
    from {
        transform: scale(1); }
    to {
        transform: scale(1.2); } }
@keyframes animaProjectsY {
    from {
        transform: translateY(-1rem); }
    to {
        transform: translateY(0); } }
@keyframes animaProjectsH {
    from {
        transform: translateX(-1rem); }
    to {
        transform: translateX(0); } }
