@mixin text--body--01 {
    font-size: 1.6rem;
    letter-spacing: initial!important;
    line-height: 1.4;
    @include media('<tablet-portrait') {
        font-size: 2rem; }
    @include media('<phone') {
        font-size: 2.5rem; } }
@mixin text--body--02 {
    font-size: 1.2rem;
    letter-spacing: initial!important;
    line-height: 1.75;
 }    // @include media('<phone')

%text--body--01, .text--body--01 {
    @include text--body--01; }
%text--body--02, .text--body--02 {
    @include text--body--02; }

//Numbered list
.page__numbers__list {
    height: 100%;
    overflow: hidden;
    white-space: break-spaces;
    color: $color-dark-gray;
    font-size: 1.6rem;
    line-height: 1.4;
    position: absolute;
    top: 0;
    left: 0; }

//Coordinates
.coordinates {
    text-transform: uppercase;
    line-height: 1.4;
    text-align: left;
    color: $color-dark-gray;
    .coordinate {
        @include flexbox(row, wrap, space-between, center, center);
        margin-bottom: 3.2rem;
        @media (min-aspect-ratio: 2/1) {
            margin-bottom: 2.4rem; }
        @include media('<tablet-portrait') {
            margin-bottom: 2rem; }
        @include media('<phone') {
            margin-bottom: 3rem; }
        &:nth-of-type(3) {
            margin-bottom: 0; }
        .shuffle-text {
            display: inline-block;
            &:nth-of-type(1) {
                width: 100%; }
            &:nth-of-type(3) {
                width: auto;
                @include media ('>=tablet-portrait', 'portrait') {
                    width: 30%; }
                @include media('<tablet-portrait') {
                    width: auto; } } } } }
